/* Avenir Next Font */
@font-face {
  font-family: 'Nunito-Regular';
  src: url('./Avenir Next/Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('./Avenir Next/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Bold';
  src: url('./Avenir Next/Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Lato Font */
@font-face {
  font-family: 'Lato-Regular';
  src: url('./Lato/Lato-Regular.woff2') format('woff2'),
    url('./Lato/Lato-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-SemiBold';
  src: url('./Lato/Lato-Semibold.woff2') format('woff2'),
    url('./Lato/Lato-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('./Lato/Lato-Bold.woff2') format('woff2'),
    url('./Lato/Lato-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
