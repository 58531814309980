@import './assets/Fonts/fonts.css';

html {
  height: 100%;
  zoom: 0.85;
  -ms-zoom: 0.85;
  -webkit-zoom: 0.85;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  -moz-transform: scale(0.85);
  -moz-transform-origin: top center;
}
